import Layout1 from '@/layout/Layout1'

export default [{
  path: '/',
  component: Layout1,
  children: [
    {
      path: 'dashboard',
      component: () => import('@components/user/Dashboard')
    },
    {
      path: 'project',
      component: () => import('@components/user/ProjectItem')
    },
    {
      path: 'projects',
      component: () => import('@components/user/ProjectList')
    }, {
      path: 'projects/:id',
      component: () => import('@components/user/ProjectItem')
    }, {
      path: 'newproject',
      component: () => import('@components/user/ProjectItem')
    },
    {
      path: 'profile',
      component: () => import('@components/user/Profile')
    },
    {
      path: 'board',
      component: () => import('@components/user/KanbanBoard')
    },
    {
      path: 'notifications',
      component: () => import('@components/user/Notifications')
    }
  ]
}]
