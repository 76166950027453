import Layout1 from '@/layout/Layout1'

export default [{
  path: '/admin',
  component: Layout1,
  children: [{
    path: 'projects',
    component: () => import('@components/admin/ProjectList')
  }, {
    path: 'projects/:id',
    component: () => import('@components/admin/ProjectItem')
  }]
}]
