<template>
  <sidenav :orientation="orientation" :class="curClasses">

    <!-- Inner -->
    <div class="sidenav-inner" :class="{ 'py-1': orientation !== 'horizontal' }">

      <sidenav-router-link icon="ion ion-ios-desktop" to="/dashboard" :exact="true">Dashboard</sidenav-router-link>

      <div v-if="role=='user'">
        <!-- <sidenav-router-link icon="ion ion-md-cube" to="/project" :exact="true">Proiect</sidenav-router-link> -->
        <sidenav-router-link icon="ion ion-md-cube" to="/projects" :exact="true">Proiecte</sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-clipboard" to="/board" :exact="true">Kanban board (soon)</sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-notifications" to="/notifications" :exact="true">Notificari (soon)</sidenav-router-link>
      </div>

      <div v-if="role=='mentor'">
        <sidenav-divider class="mb-1"/>
        <sidenav-header class="small font-weight-semibold">ASISTENT</sidenav-header>
        <sidenav-router-link icon="ion ion-md-apps" to="/admin/projects" :exact="true">Proiecte</sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-notifications" to="/admin/notifications" :exact="true">Notificari</sidenav-router-link>
      </div>

    </div>
  </sidenav>
</template>

<script>
import { Sidenav, SidenavLink, SidenavRouterLink, SidenavMenu, SidenavHeader, SidenavBlock, SidenavDivider } from '@vendor/libs/sidenav'

export default {
  name: 'app-layout-sidenav',
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider
    /* eslint-enable vue/no-unused-components */
  },

  props: {
    orientation: {
      type: String,
      default: 'vertical'
    }
  },

  data: () => ({
    role: 'user'
  }),

  created () {
    this.$http.get('/profile').then(response => {
      this.role = response.data.role
      if (!this.role || this.role === '') this.role = 'user'
    }).catch(e => {
      console.log(e)
    })
  },

  computed: {
    curClasses () {
      let bg = this.layoutSidenavBg

      if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
        bg = bg
          .replace(' sidenav-dark', '')
          .replace(' sidenav-light', '')
          .replace('-darker', '')
          .replace('-dark', '')
      }

      return `bg-${bg} ` + (
        this.orientation !== 'horizontal'
          ? 'layout-sidenav'
          : 'layout-sidenav-horizontal container-p-x flex-grow-0'
      )
    }
  },

  methods: {
    isMenuActive (url) {
      return this.$route.path.indexOf(url) === 0
    },

    isMenuOpen (url) {
      return this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal'
    },

    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    }
  }
}
</script>
