// Additional polyfills
import 'custom-event-polyfill'
import 'url-polyfill'

import Vue from 'vue'
import App from './App'
import router from './router'

import BootstrapVue from 'bootstrap-vue'

import globals from './globals'
import Popper from 'popper.js'

import axios from 'axios'
import ENV from '../../env.json'

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

Vue.config.productionTip = false

Vue.prototype.$http = axios.create({
  baseURL: process.env.NODE_ENV !== 'production' ? ENV.apiUrlLocal : ENV.apiUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
Vue.prototype.$httpPublic = axios.create({
  baseURL: process.env.NODE_ENV !== 'production' ? ENV.apiUrlLocal : ENV.apiUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

Vue.prototype.$http.defaults.timeout = 10000

Vue.prototype.$http.interceptors.request.use(
  config => {
    const token = localStorage.getItem('access_token')
    if (token) {
      config.headers.common.Authorization = 'Bearer ' + token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

Vue.prototype.$http.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  error => {
    if (error.response.status) {
      switch (error.response.status) {
        case 400:
          //  do something
          break
        case 401:
          // alert('session expired')
          router.replace({
            path: '/auth/login',
            query: { redirect: router.currentRoute.fullPath }
          })
          break
        case 403:
          router.replace({
            path: '/auth/login',
            query: { redirect: router.currentRoute.fullPath }
          })
          break
        case 404:
          // alert('page not exist')
          break
        case 502:
          setTimeout(() => {
            router.replace({
              path: '/auth/login',
              query: {
                redirect: router.currentRoute.fullPath
              }
            })
          }, 1000)
      }
      return Promise.reject(error.response)
    }
  }
)

Vue.filter('str_limit', function (value, size) {
  if (!value) return ''
  value = value.toString()

  if (value.length <= size) {
    return value
  }
  return value.substr(0, size) + '...'
})

Vue.use(BootstrapVue)

// Global RTL flag
Vue.mixin({
  data: globals
})

new Vue({
  router,
  render: h => h(App)
  // created () {
  //   // Prevent blank screen in Electron builds
  //   this.$router.push('/home')
  // }
}).$mount('#app')
